import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Card } from '../../../components/Card';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "acessibilidade",
      "style": {
        "position": "relative"
      }
    }}>{`Acessibilidade`}</h1>
    <p>{`A acessibilidade web está ganhando mais espaço no contexto de desenvolvimento de software. Dada a natureza colaborativa da web e sua importância no processo de facilitar a comunicação, precisamos assegurar o acesso à informação e `}<strong parentName="p">{`promover a mesma experiência à todos os usuários`}</strong>{`, independentemente da habilidade física, cognitiva ou plataforma e tecnologias utilizadas.`}</p>
    <blockquote cite='World Health Organization'>
  <p>
    Deficiência não é apenas um problema de saúde. É um fenômeno complexo, refletindo a interação entre as
    características do corpo de uma pessoa e as características da sociedade em que vive.
  </p>
    </blockquote>
    <h2 {...{
      "id": "ainda-nao-esta-convencido",
      "style": {
        "position": "relative"
      }
    }}>{`Ainda não está convencido?`}</h2>
    <p>{`De acordo com dados do `}<a parentName="p" {...{
        "href": "https://www.who.int/disabilities/world_report/2011/report.pdf"
      }}>{`Relatório Mundial de Deficiência 2011`}</a>{` `}<strong parentName="p">{`um bilhão de pessoas em todo o mundo vivem com algum tipo de deficiência`}</strong>{` (10% da população mundial). No Brasil, o acesso a informação é um direito garantido pela Constituição Federal. Além disso, o último `}<a parentName="p" {...{
        "href": "https://censo2010.ibge.gov.br/resultados/resumo.html"
      }}>{`Censo`}</a>{` mostrou que `}<strong parentName="p">{`45.6 milhões de pessoas`}</strong>{` (23,9% da população total) possuem alguma deficiência.`}</p>
    <Card icon='heartOutline' mdxType="Card">
  Nosso objetivo é construir produtos digitais inclusivos para aprimorar a experiência de todos os usuários.
  Acessibilidade significa tornar as pessoas independentes, garantindo que possam concluir suas tarefas em um esforço e
  tempo semelhantes aos de alguém que não tem uma deficiência.
    </Card>
    <h2 {...{
      "id": "componentes-acessiveis",
      "style": {
        "position": "relative"
      }
    }}>{`Componentes acessíveis`}</h2>
    <p>{`Os componentes do Bold foram construídos seguindo as especificações da WCAG, em conformidade com o nível AA. O Bold permite o desenvolvimento acessível, fornecendo componentes semanticamente corretos, cada um com uma
marcação `}<em parentName="p">{`ARIA`}</em>{` apropriada, para que possam ser corretamente identificados por tecnologias assistivas. No entanto, é importante ter em mente que o `}<em parentName="p">{`design system`}</em>{` é apenas a base para o desenvolvimento de
aplicações acessíveis. Recomendamos que você analise e teste seus produtos para garantir que estejam em conformidade com as
`}<a parentName="p" {...{
        "href": "Https://www.w3.org/TR/WCAG20/#conformance"
      }}>{`Normas WCAG no nível AA`}</a>{`.`}</p>
    <h2 {...{
      "id": "como-foram-feitos-os-testes-de-acessibilidade",
      "style": {
        "position": "relative"
      }
    }}>{`Como foram feitos os testes de acessibilidade?`}</h2>
    <p>{`Os componentes e princípios que compõem o Bold design foram testados para garantir que estivessem adequados à acessibilidade. O processo contou com duas etapas de teste, a primeira com ferramentas automatizadas e a segunda com usuários.`}</p>
    <p><strong parentName="p">{`Teste com ferramentas automatizadas:`}</strong>{` foram realizadas a verificação de contrastes e de adequação a versão 2.1 da WCAG, adequação à e-mag, e ferramentas de testes automatizado (pós desenvolvimento): Lighthouse da Google e Axe da Deque.`}</p>
    <p><strong parentName="p">{`Teste com usuários:`}</strong>{` foram testadas a eficiência, eficácia e satisfação dos usuários do bold através de um teste de usabilidade com o público específico:`}</p>
    <ul>
      <li parentName="ul">{`1 usuário com limitação visual (cego);`}</li>
      <li parentName="ul">{`4 usuários com limitação visual (baixa visão, daltonismo);`}</li>
      <li parentName="ul">{`2 usuários com limitação motora temporária (apenas uso de teclado).`}</li>
    </ul>
    <p>{`Tanto o teste com ferramentas quanto com usuários forneceram dados utilizados para a melhoria dos componentes e das soluções implementadas no BOLD. Para verificar a pesquisa completa, acesse: `}<a parentName="p" {...{
        "href": "https://repositorio.ufsc.br/handle/123456789/197794"
      }}>{`https://repositorio.ufsc.br/handle/123456789/197794`}</a></p>
    <h2 {...{
      "id": "melhores-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Melhores práticas`}</h2>
    <h3 {...{
      "id": "cores",
      "style": {
        "position": "relative"
      }
    }}>{`Cores`}</h3>
    <ul>
      <li parentName="ul">{`A cor não deve ser usada como o único meio visual de transmitir informações, indicar ação, solicitar
resposta ou distinguir elementos visuais;`}</li>
      <li parentName="ul">{`Limite o uso de vermelhos e verdes, porque estes são facilmente confundidos no tipo mais comum de daltonismo;`}</li>
      <li parentName="ul">{`O plug-in `}<a parentName="li" {...{
          "href": "https://www.getstark.co/"
        }}>{`Stark`}</a>{` do Sketch, possibilita ver como os protótipos são vistos por diferentes
tipos de daltonismo.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/image/accessibility-color.png",
        "alt": "Faça/Não faça. Faça: Use ícones para indicar estados de erro. Não faça: Usar apenas cor para transmitir informação"
      }}></img></p>
    <h2 {...{
      "id": "contraste",
      "style": {
        "position": "relative"
      }
    }}>{`Contraste`}</h2>
    <p>{`Em geral, o texto e as imagens devem atingir o contraste mínimo ideal de 4,5: 1 (para fontes iguais ou menores que 14 pt), exceto:`}</p>
    <ul>
      <li parentName="ul">{`Textos grandes (18,6px ou 14pt) devem manter o contraste de pelo menos 3: 1;`}</li>
      <li parentName="ul">{`Texto/imagens decorativas e estados desativados não têm requisitos de contraste;`}</li>
      <li parentName="ul">{`O logotipo ou texto que compõe uma marca não precisa estar dentro da razão de contraste.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/image/accessibility-contrast.png",
        "alt": "Faça/Não faça: Teste de contraste em tags e botões"
      }}></img></p>
    <h3 {...{
      "id": "foco",
      "style": {
        "position": "relative"
      }
    }}>{`Foco`}</h3>
    <p>{`O componente focado pelo teclado deve estar claramente marcado e a área de seleção deve ser clicável. Por padrão,
`}<em parentName="p">{`links`}</em>{` e elementos de formulário já exibem a borda destacada quando recebem o foco do teclado. Essa borda
pode ser modificada via CSS para aprimorar o realce, mas `}<strong parentName="p">{`não`}</strong>{` deve ser removida.`}</p>
    <p>{`Usamos e recomendamos que a espessura mínima da borda seja de 2px.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/accessibility-focus.png",
        "alt": "Foco no botão e checkbox"
      }}></img></p>
    <h2 {...{
      "id": "tecnologias-assistivas",
      "style": {
        "position": "relative"
      }
    }}>{`Tecnologias assistivas`}</h2>
    <p>{`Quando as pessoas começam desenvolver com a acessibilidade em mente, é um erro comum adicionar foco a todos os elementos da página,
incluindo texto e títulos. Isso dificulta a navegação de usuários que podem ver e ainda dificulta a navegação para quem depende de um leitor de tela,
porque as tecnologias assistivas já fornecem foco para esses elementos.`}</p>
    <p>{`Títulos, blocos de texto e campos desabilitados `}<strong parentName="p">{`não`}</strong>{` devem receber foco.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/accessibility-assistivetech.png",
        "alt": "Campo de texto e botão desabilitados"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      